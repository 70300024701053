import { ReactNode, useCallback, useState } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMCloseButton } from '../RMCloseButton/RMCloseButton';
import { RMDialog } from '../RMDialog';

import { Button, Footer, LeftButtons, RightAdornmentWrapper } from './RMConfirmationModal.styles';

interface RMConfirmationModalProps {
  open: boolean;
  title: string;
  message?: string;
  type?: 'danger' | 'primary';
  confirmLabel?: string | null;
  confirmDisabled?: boolean;
  cancelLabel?: string | null;
  rightAdornment?: ReactNode;
  btnFullWidth?: boolean;
  leftButtons?: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: (() => void) | null;
}

export function RMConfirmationModal({
  open,
  title,
  message,
  type = 'primary',
  confirmLabel = 'Confirm',
  confirmDisabled,
  cancelLabel = 'Cancel',
  rightAdornment = null,
  btnFullWidth = false,
  leftButtons = null,
  onConfirm,
  onCancel,
  onClose = onCancel,
}: RMConfirmationModalProps) {
  const isMobile = useIsMobileViewport();
  const [processing, setProcessing] = useState(false);

  const handleAction = useCallback(async (action: (() => void) | undefined) => {
    try {
      setProcessing(true);
      await Promise.resolve(action?.());
    } finally {
      setProcessing(false);
    }
  }, []);

  return (
    <RMDialog.Root open={open} onClose={() => onClose?.()}>
      <RMDialog.Content>
        <RMDialog.Header
          title={title}
          message={message}
          rightAdornment={
            <RightAdornmentWrapper>
              {rightAdornment}
              {onClose && <RMCloseButton onClick={onClose} />}
            </RightAdornmentWrapper>
          }
        />

        <RMDialog.Footer>
          <Footer>
            {leftButtons && <LeftButtons>{leftButtons}</LeftButtons>}
            {cancelLabel && (
              <Button
                background="neutral"
                fullWidth={isMobile || btnFullWidth}
                autoLoading
                disabled={processing}
                onClick={() => handleAction(onCancel)}
              >
                {cancelLabel}
              </Button>
            )}
            {confirmLabel && (
              <Button
                background={type}
                fullWidth={isMobile || btnFullWidth}
                autoLoading
                disabled={processing || confirmDisabled}
                onClick={() => handleAction(onConfirm)}
              >
                {confirmLabel}
              </Button>
            )}
          </Footer>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
