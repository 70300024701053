import { styled } from '@linaria/react';

import { RMButton } from '../RMButton/RMButton';

export const Button = styled(RMButton)`
  min-width: 6.25rem;

  body[data-mobile='true'] & {
    min-width: unset;
  }
`;

export const RightAdornmentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-xl);
  width: 100%;

  body[data-mobile='true'] & {
    gap: var(--spacing-md);
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

export const LeftButtons = styled.div`
  body[data-mobile='false'] & {
    margin-right: auto;
  }
`;
